<template>
  <div>
    <heade></heade>
    <div class="com">
      <div class="com_box">
        <div class="com-left">
          <div
            class="com-left-top title"
            :class="[
              titleLength <= 15 ? 'title-height-middle' : '',
              titleLength >= 16 && titleLength < 20 ? 'title-height-big' : '',
              titleLength >= 20 ? 'title-height-larger' : '',
            ]"
          >
            {{ pract.name }}
          </div>
          <div class="com_title_box">
            <div class="com_title nice-scroll">
              <div
                v-for="(items, indexs) in practManualEdition.division_category"
                :key="indexs"
                class="com_title_list"
              >
                <div class="com-left-foot">
                  <div class="">
                    <div
                      v-for="(itemc, indexc) in items.division"
                      :key="indexc"
                      @click="
                        getpraparationDivisionContentByContenId(
                          itemc.division_content_id,
                          itemc.charge_mode,
                          indexc
                        )
                      "
                      :id="itemc.division_content_id"
                    >
                      <div
                        :id="'trigger' + indexc"
                        v-trigger
                        class="com-left-foot-top"
                        :class="
                          queryWhere.id == itemc.division_content_id
                            ? 'addclass'
                            : ''
                        "
                      >
                        {{ itemc.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="com-right preparation">
          <van-tabs v-model="active">
            <van-tab title="教学目标">
              <div v-html="mubiao"></div>
            </van-tab>
            <van-tab title="教学内容">
              <div v-html="kewen"></div>
            </van-tab>
            <van-tab v-if="shuoming != ''" title="教学说明">
              <div v-html="shuoming"></div>
            </van-tab>
            <van-tab v-if="sheji != ''" title="教学设计">
              <div v-html="sheji"></div>
            </van-tab>
            <van-tab title="相关素材">
              <div v-html="sucai"></div>
            </van-tab>
            <van-tab title="延伸学习">
              <div v-html="xuexi"></div>
            </van-tab>
          </van-tabs>
        </div>
        <div class="com-btt">
          <div v-for="(item, index) in pra" :key="index">
            <router-link
              :to="{
                path: '/preparationDetail',
                query: { id: item.id, edition_id: 1 },
              }"
              class="com-bbt-item"
              :class="
                item.id == pract.id ? 'com-bbt-item active2' : 'com-bbt-item'
              "
              >{{ item.name }}</router-link
            >
          </div>
        </div>
      </div>
    </div>

    <foot></foot>
  </div>
</template>
<script>
import Vue from "vue";
import { Tab, Tabs } from "vant";
Vue.use(Tab);
Vue.use(Tabs);
import "@css/ico.css";
import "@css/sanji.css";
import "@css/foot.css";
import "@css/resetui.css";
import $ from "jquery";
import $store from "../../store";
import { scroll } from "@utils/scroll";
import heade from "@components/heade.vue";
import foot from "@components/foot.vue";
import {
  getpraparationCourses,
  getpraparationCoursess,
  getpraparationDivisionContentByContenId,
} from "@api/public";
export default {
  components: {
    heade,
    foot,
  },
  props: {},
  data() {
    return {
      pra: [],
      pract: {},
      practManualEdition: [],
      queryWhere: {},
      mubiao: "",
      kewen: "",
      shuoming: "",
      sheji: "",
      sucai: "",
      xuexi: "",
      active: 0,
      titleLength: 0,
    };
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },

  mounted: function () {
    let that = this;
    getpraparationCourses(that.queryWhere).then(function (res) {
      that.$set(that, "pra", res.data);
    });
    getpraparationCoursess(that.queryWhere).then(function (res) {
      that.$set(that, "pract", res.data);
      that.$set(that, "practManualEdition", res.data.manualEdition[0]);
      scroll($(".nice-scroll"));
    });
  },
  created: function () {
    this.queryWhere = this.$route.query;
    document.title = this.$route.meta.title;
  },

  methods: {
    getpraparationDivisionContentByContenId: function (id, charge_mode, index) {
      if (
        charge_mode == 1 ||
        ($store.state.app.userInfo != null &&
          $store.state.app.userInfo.is_empower)
      ) {
        let that = this;
        that.queryWhere = {};
        that.queryWhere.id = id;
        that.active = 0;
        getpraparationDivisionContentByContenId(that.queryWhere).then(function (
          res
        ) {
          that.$set(that, "mubiao", res.data.mubiao);
          that.$set(that, "kewen", res.data.kewen);
          that.$set(that, "shuoming", res.data.shuoming);
          that.$set(that, "sheji", res.data.sheji);
          that.$set(that, "sucai", res.data.sucai);
          that.$set(that, "xuexi", res.data.xuexi);
        });
      } else {
        if (!$store.state.app.token) {
          layui.use(["layer"], function () {
            var layer = layui.layer;
            layer.open({
              title: "",
              content: "您还未登录！是否去登录？",
              btn: ["是", "否"],
              closeBtn: 0,
              skin: "layui-layer-molv",
              yes: function () {
                window.location.href = "/login";
              },
            });
          });
        } else if (!$store.state.app.userInfo.is_empower) {
          layui.use(["layer"], function () {
            var layer = layui.layer;
            layer.open({
              title: "",
              content:
                "该部分内容为课题实验校、示范区会员账号专属内容，如需加入课题实验，请点击下方“课题申报”链接，按照要求提交信息，经课题组审核批准后，方可成为课题实验校、示范区，获得专属课题账号，正常查看全部内容。",
              closeBtn: 0,
              btn: ["课题申报", "关闭"],
              success: function (layero) {
                var btn = layero.find(".layui-layer-btn");
                btn.find(".layui-layer-btn0").attr({
                  href: "/application/shenbaobiao",
                });
              },
            });
          });
        }
      }
    },
  },
  updated() {
    this.titleLength = $(".title").text().length;
  },
  directives: {
    trigger: {
      inserted(el, binging) {
        el.id == "trigger0" ? el.click() : null;
      },
    },
  },
  destroyed: function () {
    $(".nice-scroll").getNiceScroll().hide();
    $(".nicescroll-cursors").hide();
    $(".nicescroll-rails").remove();
  },
};
</script>

<style scoped>
.com {
  width: 100%;
  min-width: 1484px;
  height: 1660px;
  background: url(../../assets/images/jiaoshisanji.jpg);
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}
.com_box {
  width: 1484px;
  height: 1660px;
  position: relative;
  margin: 0 auto;
}

.com-right::-webkit-scrollbar {
  display: none;
}
.com-right {
  overflow-y: auto;
}

.com-btt {
  width: 780px;
  height: 226px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 1270px;
  left: 350px;
}
.com-btt > div {
  width: 15px;
  height: 100%;
  display: flex;
  align-items: center;
}
.com-bbt-item {
  font-family: "楷体";
  font-size: 20px;
}
.com_right {
  overflow-y: auto;
}
.com-left-con {
  display: flex;
  justify-content: space-around;
  color: #fff;
  cursor: pointer;
}
.com-left-fot {
  height: 500px;
  overflow-y: auto;
}
.com-left-fot::-webkit-scrollbar {
  display: none;
}
.active {
  background-color: red;
}
.fot {
  text-align: center;
  color: #fff;
  margin-top: 20px;
  cursor: pointer;
}
.addclass {
  color: #d8c8a5;
}
.com-right-ul {
  width: 100%;
  height: 20px;
  display: flex;
  align-content: space-between;
}
.active2 {
  color: #900c0c;
}
</style>
